<template>
    <b-modal title="Podrobnosti zaloge materiala" v-model="stockModalActive" size="lg" centered hide-footer>
        <b-card>
            <table>
                <tr>
                    <th class="pb-50">Material</th>
                    <td class="pb-50 pl-1">{{ stock.material.material }}</td>
                </tr>
                <tr>
                    <th class="pb-50">Material šifra</th>
                    <td class="pb-50 pl-1">{{ stock.material.id }}</td>
                </tr>

            </table>
        </b-card>
        <b-card>
            <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm" class="float-right col-sm-3 mb-1"/>
            <b-table striped responsive bordered hover :items="stock.pieces" :fields="fields" :filter="search"
                     :per-page="perPage" :current-page="currentPage" filter-debounce="500">

            </b-table>
            <b-row>
                <b-col class="float-left">
                    <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
                        <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key" @click="setPerPage(item)">
                            {{ item }}
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col sm="7" md="6" class="float-right">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"/>
                </b-col>
            </b-row>
        </b-card>

    </b-modal>
</template>

<script>

    export default {
        data() {
            return {
                stockModalActive: false,
                stock: {},
                search: '',
                perPage: 10,
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                totalRows: 1,
                selected: {},
                fields: [
                    {key: 'length', label: 'Dolžina', sortable: true, class: 'text-center'},
                    {key: 'quantity', label: 'Količina', sortable: true, class: 'text-center'}
                ],
                filters: {length: '', material_type: ''}
            }
        },
        methods: {
            open(item) {
                if (item !== null) {
                    this.stock = item
                    this.stockModalActive = true
                }
            },
            setPerPage(item) {
                this.perPage = item
            }
        }
    }
</script>

<style>

</style>
